import React from 'react';

const Sidebar = () => {
    return (
        <div className="sidebar-sticky">
            <div className="navigation-bar">
                <div className="container">
                    <h1 className="logo-text"><a className="logo-text animated" href="/">Boy d'Hont</a></h1>
                    <p className="footer-text"><a className="footer-text">HighTech Culture</a> | Architecture, BIM and Digital Mentoring</p>
                    <nav>
                        <a className="animated" href="/#about">
                            <p className="menu-text">About</p>
                        </a>
                        <a className="animated" href="/#projects">
                            <p className="menu-text">Projects</p>
                        </a>
                        <a className="animated" href="/#contact">
                            <p className="menu-text">Contact</p>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;