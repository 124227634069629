import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <p className="footer-text">Copyright 2024 © <a className="footer-text">Boy d'Hont</a></p>
        </div>
    );
};

export default Footer;