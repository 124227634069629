import React from 'react';

const InformationSticky = ({ location, date }) => {

    return (
        <div className="information-sticky">
            <div className="container">
                <p className="subtext"> {location}</p>
                <p className="subtext">{date}</p>
            </div>
        </div>
    );
};

export default InformationSticky;