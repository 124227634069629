import React from 'react';

import InformationSticky from '../InformationSticky';
import Divider from '../Divider';
import Title from '../Title';
import Subtitle from '../Subtitle';
import MenuItem from '../MenuItem';

const HomeContent = () => {
    return (
        <>
            <InformationSticky location="" date="" />
            <div className="content">
                <div className="container">
                    <section id="about">
                        <Title text="Pushing modern Workflows in Architecture"/>
                        <p>I am an architect, involved in large-scale residential, hotel and office design projects. Hacking and tutoring BIM tools on the way. Official member of the Chamber of Architects Berlin.</p>
                    </section>
                    <section id="projects">
                        <Divider/>
                        <Subtitle text="Recent Projects"/>
                        <MenuItem to="/projects_Lunar" text="Lunar, the Library for Parametric Design" />
                        <MenuItem to="/projects_Gan" text="GAN in the Design Office" />
                        <MenuItem to="/projects_OrganicShapes" text="Organic Shapes for the Masses" />
                        <MenuItem to="/projects_CoporateIntelligence" text="Corporate Intelligence" />
                        <MenuItem to="/projects_DrawingAssistant" text="The Artificial Drawing Assistant" />
                    </section>
                    <section id="contact">
                        <Divider/>
                        <Subtitle text="Contact"/>
                        <p>I am always open for new ideas and collaborations. <a href="mailto: contact@bdhont.net">Send an Email</a>.</p>
                    </section>
                </div>
            </div>
        </>
    );
};

export default HomeContent;