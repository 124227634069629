import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ to, text }) => {
    return (
        <NavLink to={to} className="animated" activeclassname="active">
            <h1>{text}</h1>
        </NavLink>
    );
};

export default MenuItem;