import React from 'react';

import InformationSticky from '../../InformationSticky';
import Divider from '../../Divider';
import Title from '../../Title';
import Quote from '../../Quote';
import Image from '../../Image';

import imagePathA from './Warehouse.jpg';

const ProjectCorporateIntelligence = () => {
    return (
        <>
            <InformationSticky location="Stuttgart (DE)" date="Feb 12, 2018" />
            <div className="content">
                <div className="container">
                    <Title text="Corporate Intelligence" />
                    <p>The work environment of the future can't escape the strong influence of artifical intelligence. According to <a>a famous research</a> by C.B Frey and M.A. Osborne, 47 percent of the current jobs will cease to exist due digital automation.
                        Contemporary AI technologies struggle with the execution of unclear and dynamic tasks. Humans still aid these machine learning systems as trainers, explainers or ethical sustainers. In the near future, artifical intelligence will master
                        the skills of these hominid workers as well. Internet giants as Amazon and Google invest significantly in the technology behind these digital minds, but an architectural typology that truely embraces artificial intelligence still misses.</p>
                    <p>The office building of the future accomodates large server areas to power the artifical intellgence that performs the real work and checks other artifical intelligence. A generative fassade holds the optimal shape to cool the computation
                        facilities with natural air- and water flows to increase energetic sustainability. Only floors with high spatial qualities grant humans a creative place for the generation of new ideas, generis and personal connection.</p>
                    <Divider />
                    <Quote text="The architectural typology for AI driven corporations serves as a gateway to both the human- and digital world, mixing the architectural language of the international style with a flavor of meta-modernism. Large skyscrapers no longer bear space for cubicles nor open offices, but for servers that power the AI that performs the real work." />
                    <Image src={imagePathA} />
                    <h2>Context</h2>
                    <p>The project is a thought experiment in the context of the <a href="https://www.nonarchitecture.eu/">Non-Architecture Competitions</a>, in collaboration with <a>Bas Rongen</a>.</p>
                </div>
            </div>
        </>
    );
};

export default ProjectCorporateIntelligence;